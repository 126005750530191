import React, { Fragment } from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import PasswordIcon from "@mui/icons-material/Password";
import SetPasswordModal from "../../../components/modals/SetPasswordModal";
import NxIconButton from "../../../nix_components/ui_components/controls/nx_icon_button";

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordItem: null,
      loaded: false,
      filters: null,
    };
  }

  buttons = {
    setPassword: (params) => {
      return (
        <NxIconButton
          tooltip="Set Password"
          aria-label="edit"
          color="default"
          onClick={() => this.setState({ passwordItem: params.row })}
        >
          {/* <PasswordIcon /> */}
        </NxIconButton>
      );
    },
  };

  formConfig = {
    rows: [["product_id", "subscription_active"]],
  };

  gridProps = {
    fields: [
      "email",
      "subscription_active",
      "subscription_type",
      "subscription_started",
      "subscription_ended",
    ],
  };

  gridMobileProps = {
    fields: [
      "email",
      "subscription_type",
      // "subscription_active",
      // "subscription_started",
      "subscription_ended",
    ],
  };

  setPasswordItemNull = () => {
    console.log("sejvujem");

    this.state.passwordItem = null;
    this.forceUpdate();
  };

  setPassword = (password, user) => {
    global.UF.makeRequest(
      `PUT`,
      `/api/auth/password`,
      { id: user.id, password: password },
      true,
      () => {
        this.setState({ passwordItem: null });
      },
      (error) => {
        if (error) {
          console.error(error);
        }
      }
    );
  };

  renderSetPasswordModal = () => {
    return (
      <SetPasswordModal
        open={this.state.passwordItem !== null}
        onClose={() => this.setState({ passwordItem: null })}
        onSetPassword={(password) =>
          this.setPassword(password, this.state.passwordItem)
        }
      />
    );
  };

  fetchData = () => {};

  getFetchData = (fetchData, filters) => {
    this.fetchData = fetchData;
    this.setState({ filters });
  };

  onSave = async (dataItem, callback) => {
    // console.log("sejvujem");

    dataItem.email = dataItem.email.trim().toLowerCase();
    // console.log(dataItem.GetChangedFields(), "type");
    dataItem.Save(async (response) => {
      if (response?.hasOwnProperty("error")) {
        global.UF.setAlertVisibility(true, response.error, "error");
        return;
      }
      console.log(response.product_id, "ovo je response");
      if (response.hasOwnProperty("product_id")) {
        console.log("ima product id");
      }

      if (dataItem.isNew && response.hasOwnProperty("id") && response.id) {
        const id = response.id;
        try {
          await global.UF.makeRequest_v2(
            `POST`,
            `/api/set-password`,
            { id: id, password: dataItem.password },
            true
          );

          if (callback) {
            console.log("callback", callback);
            callback();
          }
        } catch (err) {
          console.error(err);
          global.UF.setAlertVisibility(true, err.toString(), "error");
        }
      } else {
        if (callback) {
          console.log("callback", callback);
          callback();
        }
      }
    });
  };

  generateAndSetFilters = (state) => {
    const filters = {};
    const { date_from, date_to } = state;

    const date_from_obj = new Date(date_from);
    const date_to_obj = new Date(date_to);
    const mysqlTimeStampFrom = date_from_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const mysqlTimeStampTo = date_to_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    if (date_from || date_to) {
      filters.custom_filter = `${
        mysqlTimeStampTo ? `'${mysqlTimeStampTo}' >= users.created_at` : ""
      } ${mysqlTimeStampTo && mysqlTimeStampFrom ? "AND" : ""} ${
        mysqlTimeStampFrom ? `users.created_at >= '${mysqlTimeStampFrom}'` : ""
      }`;
    }

    this.setState({ filters }, () => {
      global.UF.dataProvider.datastructure["users"].filters =
        this.state.filters;

      this.fetchData();
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.passwordItem && this.renderSetPasswordModal()}
        <UF_Content
          table="view_users"
          title="Users"
          gridProps={this.gridProps}
          gridMobileProps={this.gridMobileProps}
          onSave={this.onSave}
          fetchData={this.getFetchData}
          formConfig={this.formConfig}
          buttons={this.buttons}
          edit={true}
          // delete={true}
        />
      </Fragment>
    );
  }
}
