import data_structure from "./data_structure.json";
import data_types from "./data_types";
import React from "react";
import { setValidations, validators } from "./data_validators";

const data_configs = {};
const static_configs = {
  users: {
    ignoreValidation: [
      "id",
      "status",
      "age",
      "user_guid",
      "reset_password",
      "password",
      "role",
      "is_active",
    ],
    fields: {
      id: {
        hide: true,
      },
      first_name: {
        field: "first_name",
        label: "First name",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.hasSpecialCharAsFirstChar,
        ],
      },
      last_name: {
        field: "last_name",
        label: "Last name",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.hasSpecialCharAsFirstChar,
        ],
      },
      email: {
        field: "email",
        label: "Email",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.isNotEmail,
        ],
      },
      number: {
        field: "number",
        label: "Number",
      },
      role: {
        field: "role",
        label: "Role",
        datatype: data_types.reference,
        reference: "role",
      },
      budget: {
        field: "budget",
        label: "Budget",
        read_only: true,
        disabled: true,
        renderCell: (dataItem) => {
          const { budget } = dataItem.row;
          return (
            <div>
              {new Intl.NumberFormat("sr-SR", {
                style: "currency",
                currency: "RSD",
              }).format(budget)}
            </div>
          );
        },
      },
      password: {
        field: "password",
        label: "Password",
      },
      is_active: {
        field: "is_active",
        label: "Active",
        validators: [validators.isNull],
      },
    },
    errorHandling: (requestType, error, dataItem) => {
      const errorMsg = error.error;
      if (errorMsg.includes("users.email_UNIQUE")) {
        return {
          code: error.code,
          error: "User with that email already exists!",
        };
      }
      return error;
    },
  },
  deposits: {
    ignoreValidation: ["id"],
    fields: {
      id: {
        hide: true,
      },
      user_id: {
        field: "user_id",
        label: "User",
        datatype: data_types.reference,
        reference: "users",
        validators: [validators.isNull],
        defaultValue: localStorage.getItem("user_id"),
      },
      date: {
        field: "date",
        label: "Date",
        datatype: data_types.date,
      },
      deposit: {
        field: "deposit",
        label: "Deposit",
      },
    },
  },
  expanses: {
    ignoreValidation: ["id"],
    fields: {
      id: {
        hide: "true",
      },
      category_id: {
        field: "category_id",
        label: "Category",
        datatype: data_types.autocomplete,
        reference: "expanses_categories",
        addable: true,
        validators: [validators.isNull],
        addable_field: "category",
      },
      source_id: {
        field: "source_id",
        label: "Source",
        datatype: data_types.autocomplete,
        reference: "expanses_sources",
        addable: true,
        validators: [validators.isNull],
        addable_field: "source",
      },
      user_id: {
        field: "user_id",
        label: "User",
        datatype: data_types.reference,
        reference: "users",
        validators: [validators.isNull],
        defaultValue: localStorage.getItem("user_id"),
      },
      cost: {
        field: "cost",
        label: "Cost",
        validators: [validators.isNull, validators.isGreaterThanZero],
        renderCell: (dataItem) => {
          const { cost } = dataItem.row;
          return (
            <div>
              {new Intl.NumberFormat("sr-SR", {
                style: "currency",
                currency: "RSD",
              }).format(cost)}
            </div>
          );
        },
      },
      date: {
        field: "date",
        label: "Date",
        datatype: data_types.date,
      },
      is_equal: {
        field: "is_equal",
        label: "Equal",
        defaultValue: 1,
      },
      notes: {
        field: "notes",
        label: "Notes",
      },
      is_private: {
        field: "is_private",
        label: "Private",
      },
    },
  },
  expanses_categories: {
    ignoreValidation: ["id"],
    fields: {
      id: {
        hide: true,
      },
      category: {
        field: "category",
        label: "Categories",
      },
      created_at: {
        field: "created_at",
        label: "created_at",
      },
      updated_at: {
        field: "updated_at",
        label: "updated_at",
      },
    },
  },
  view_expanses_categories: {
    id: ["month_start", "category"],
    fields: {
      month_start: {
        field: "month_start",
        label: "Month",
      },
      total_cost: {
        field: "total_cost",
        label: "Total Cost",
        renderCell: (dataItem) => {
          const formater = new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          });
          return <div>{formater.format(dataItem.row.total_cost)}</div>;
        },
      },
      category: {
        field: "category",
        label: "Category",
      },
    },
  },
  view_expanses_categories_by_user: {
    id: ["month_start", "category", "user_id"],
    fields: {
      month_start: {
        field: "month_start",
        label: "Month",
      },
      total_cost: {
        field: "total_cost",
        label: "Total Cost",
        renderCell: (dataItem) => {
          const formater = new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          });
          return <div>{formater.format(dataItem.row.total_cost)}</div>;
        },
      },
      category: {
        field: "category",
        label: "Category",
      },
      user_id: {
        field: "user_id",
        label: "User",
        datatype: data_types.reference,
        reference: "users",
      },
    },
  },
  expanses_sources: {
    ignoreValidation: ["id"],
    fields: {
      id: {
        hide: true,
      },
      source: {
        field: "source",
        label: "Sources",
      },
      created_at: {
        field: "created_at",
        label: "created_at",
      },
      updated_at: {
        field: "updated_at",
        label: "updated_at",
      },
    },
  },
  view_profile_participations: {
    sorting: [{ field: "date", sort: "desc" }],
    fields: {
      id: {
        hide: true,
      },
      type_id: {
        hide: true,
      },
      user: {
        field: "user",
        label: "Initials",
        renderCell: (dataItem) => {
          const { user } = dataItem.row;

          return <div>{user.match(/[A-Z]/g).join("")}</div>;
        },
      },
      category_id: {
        field: "category_id",
        label: "Category",
        datatype: data_types.autocomplete,
        reference: "expanses_categories",
        validators: [validators.isNull],
      },
      source_id: {
        field: "source_id",
        label: "Source",
        datatype: data_types.autocomplete,
        reference: "expanses_sources",
        validators: [validators.isNull],
      },
      date: {
        field: "date",
        label: "Date",
        datatype: data_types.date,
      },
      cost: {
        field: "cost",
        label: "Cost",
        renderCell: (dataItem) => {
          const { cost, type } = dataItem.row;

          return (
            <div
              style={type === "Expense" ? { color: "red" } : { color: "green" }}
            >
              {type === "Expense"
                ? "-" +
                  new Intl.NumberFormat("sr-SR", {
                    style: "currency",
                    currency: "RSD",
                  }).format(cost)
                : "+" +
                  new Intl.NumberFormat("sr-SR", {
                    style: "currency",
                    currency: "RSD",
                  }).format(cost)}
            </div>
          );
        },
      },
      type: {
        field: "type",
        label: "Type",
      },
    },
  },
  view_participations: {
    fields: {
      expanse_id: {
        hide: true,
      },
      cost: {
        field: "cost",
        label: "Cost",
      },
      user: {
        field: "user",
        label: "User",
      },
    },
  },
  view_deposits: {
    sorting: [{ field: "date", sort: "desc" }],
    fields: {
      id: {
        hide: true,
      },
      user_id: {
        field: "user_id",
        label: "Initials",
        datatype: data_types.reference,
        reference: "users",
        validators: [validators.isNull],
        filterable: true,
        renderCell: (dataItem) => {
          const { user } = dataItem.row;

          return <div>{user.match(/[A-Z]/g).join("")}</div>;
        },
      },
      deposit: {
        field: "deposit",
        label: "Deposit",
        renderCell: (dataItem) => {
          const { deposit } = dataItem.row;
          return (
            <div style={{ color: "green" }}>
              {"+" +
                new Intl.NumberFormat("sr-SR", {
                  style: "currency",
                  currency: "RSD",
                }).format(deposit)}
            </div>
          );
        },
      },
      date: {
        field: "date",
        label: "Date",
        datatype: data_types.date,
      },
    },
  },
  view_expanses: {
    sorting: [{ field: "date", sort: "desc" }],
    fields: {
      id: {
        hide: true,
      },
      user_id: {
        field: "user_id",
        label: "Initials",
        datatype: data_types.reference,
        reference: "users",
        validators: [validators.isNull],
        filterable: true,
        renderCell: (dataItem) => {
          const { user } = dataItem.row;

          return <div>{user.match(/[A-Z]/g).join("")}</div>;
        },
      },
      category_id: {
        field: "category_id",
        label: "Category",
        datatype: data_types.reference,
        reference: "expanses_categories",
        validators: [validators.isNull],
        filterable: true,
      },
      source_id: {
        field: "source_id",
        label: "Source",
        datatype: data_types.reference,
        reference: "expanses_sources",
        validators: [validators.isNull],
        filterable: true,
      },
      cost: {
        field: "cost",
        label: "Cost",
        renderCell: (dataItem) => {
          const { cost } = dataItem.row;
          return (
            <div style={{ color: "red" }}>
              {"-" +
                new Intl.NumberFormat("sr-SR", {
                  style: "currency",
                  currency: "RSD",
                }).format(cost)}
            </div>
          );
        },
      },
      date: {
        field: "date",
        label: "Date",
        datatype: data_types.date,
      },

      is_private: {
        field: "is_private",
        label: "Private",
      },
    },
  },
  view_recent_activities: {
    sorting: [{ field: "date", sort: "desc" }],
    fields: {
      id: {
        hide: true,
      },
      type_id: {
        hide: true,
      },
      user_id: {
        field: "user_id",
        label: "Initials",
        datatype: data_types.reference,
        reference: "users",
        validators: [validators.isNull],
        filterable: true,
        renderCell: (dataItem) => {
          const { user } = dataItem.row;

          return <div>{user.match(/[A-Z]/g).join("")}</div>;
        },
      },
      cost: {
        field: "cost",
        label: "Cost",
        renderCell: (dataItem) => {
          const { cost, type } = dataItem.row;

          return (
            <div
              style={type === "expense" ? { color: "red" } : { color: "green" }}
            >
              {type === "expense"
                ? "-" +
                  new Intl.NumberFormat("sr-SR", {
                    style: "currency",
                    currency: "RSD",
                  }).format(cost)
                : "+" +
                  new Intl.NumberFormat("sr-SR", {
                    style: "currency",
                    currency: "RSD",
                  }).format(cost)}
            </div>
          );
        },
      },
      date: {
        field: "date",
        label: "Date",
        datatype: data_types.date,
      },
      type: {
        filterable: true,
        field: "type",
        label: "Type",
        datatype: data_types.reference,
        reference: "recentActivitiesTypes",
      },
    },
  },
  view_users: {
    ignoreValidation: ["id"],
    fields: {
      product_id: {
        field: "product_id",
        label: "Subscription type",
        datatype: data_types.reference,
        reference: "subscriptionTypes",
        filterable: true,
        validators: [validators.isNull],
      },

      subscription_active: {
        field: "subscription_active",
        label: "Subscription",
        datatype: data_types.reference,
        reference: "activeSubscription",
        filterable: true,
        validators: [validators.isNull],
      },
    },
  },
};

const parseDataType = (name, type) => {
  if (type.indexOf("char") > -1) {
    return data_types.string;
  }

  if (type.indexOf("enum") > -1) {
    return data_types.string;
  }

  switch (type) {
    case "timestamp":
      return data_types.time;
    case "date":
      return data_types.datetime;
    case "datetime":
      return data_types.datetime;
    case "time":
      return data_types.time;
    case "int":
    case "double":
      return data_types.number;
    case "tinyint(1)":
    case "tinyint":
    case "bit(1)":
      return data_types.boolean;
    case "json":
      return data_types.json;
    case "text":
    case "longtext":
    case "varchar(500)":
      return data_types.string;
  }

  if (
    type.indexOf("int") > -1 ||
    type.indexOf("float") > -1 ||
    type.indexOf("long") > -1 ||
    type.indexOf("decimal") > -1
  ) {
    return data_types.number;
  }

  console.error(`New datatype for field : ${name} that is type : ${type}`);
  return data_types.string;
};

const capitalizeFirstLetter = (string) => {
  if (string.length) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

const generateFieldsName = (label) => {
  if (label.includes("_")) {
    return `${capitalizeFirstLetter(
      label.split("_")[0]
    )} ${capitalizeFirstLetter(label.split("_")[1])}`;
  } else {
    return capitalizeFirstLetter(label);
  }
};

const handleCustomRenderCell = (field) => {
  if (!field.hasOwnProperty("renderCell")) {
    if (field.Field.toLowerCase().includes("email")) {
      field.renderCell = (params) => {
        return (
          <a style={{ textDecoration: "none" }} href={`mailto:${params.value}`}>
            {params.value}
          </a>
        );
      };
    }
    if (field.Field.toLowerCase().includes("phone")) {
      field.renderCell = (params) => {
        return (
          <a style={{ textDecoration: "none" }} href={`tel:${params.value}`}>
            {params.value}
          </a>
        );
      };
    }
  }
};

Object.keys(data_structure).forEach((table) => {
  data_configs[table] = {};
  let fields = data_structure[table].fields;
  let curConfig = data_configs[table];
  curConfig.fields = {};
  curConfig.fieldsArr = [];
  if (static_configs[table]) {
    curConfig.ignoreValidation = static_configs[table].ignoreValidation;
    curConfig.sorting = static_configs[table].sorting;
    curConfig.filters = static_configs[table].filters;
    curConfig.additionalData = static_configs[table].additionalData;
    curConfig.errorHandling = static_configs[table].errorHandling;
    curConfig.id = static_configs[table].id;
  }

  fields.forEach((field) => {
    let curField = {
      ...field,
      field: field.Field,
      name: field.Field,
      label: generateFieldsName(field.Field),
      nullable: field.Null != "NO",
      datatype: parseDataType(field.Field, field.Type),
    };

    handleCustomRenderCell(curField);

    if (static_configs[table] && static_configs[table].fields[field["Field"]]) {
      curField = Object.assign(
        curField,
        static_configs[table].fields[field["Field"]]
      );
    }
    if (curField.datatype === data_types.number) {
      if (!curField.hasOwnProperty("min")) {
        curField.min = 0;
      }
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      curField.datatype == data_types.boolean
    ) {
      curField.defaultValue = false;
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      (curField.datatype == data_types.date ||
        curField.datatype == data_types.datetime)
    ) {
      let date = new Date();
      curField.timezone = "UTC";
      curField.defaultValue = `${date.getUTCFullYear()}-${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
    }
    if (curField.datatype == "time") {
      curField.timezone = "UTC";
    }
    if (curField.field == "updated_at") {
      curField.defaultValue = null;
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      curField.datatype == data_types.color
    ) {
      curField.defaultValue = "#000";
    }
    curConfig.fields[field["Field"]] = curField;
    curConfig.fieldsArr.push(curField);
  });
  setValidations(data_configs[table]);
});

export default data_configs;
