import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemDropDown from "./controls/ListItemDropDown";
import ListItem from "./controls/ListItem";
import withRouter from "../../../components/withRouter";
import { Fragment, useEffect, useState } from "react";
import Grid from "../../layouts/Grid/Grid";

function Menu(props) {
  const [profile, setProfile] = useState(null);
  const handleActiveItem = (item) => {
    if (item.hasOwnProperty("url")) {
      if (item.url.includes("..")) {
        item.active =
          item.url.split("..")[1] === props.router.location.pathname;
      } else {
        const url = item.url.split("/")[1];
        item.active = props.router.location.pathname.includes(url);
      }
    } else {
      if (item.hasOwnProperty("children")) {
        item.children.forEach((child) => {
          if (child.hasOwnProperty("url")) {
            child.active =
              child.url.split("..")[1] === props.router.location.pathname;
            item.active = child.active === true;
          }
        });
        item.active = item.children.findIndex((child) => child.active) > -1;
        item.open = item.active;
      } else {
        item.active = false;
      }
    }
  };

  const removeTrailingSlash = (str) => {
    if (str.charAt(str.length - 1) === "/") {
      return str.slice(0, -1); // Remove the last character
    }
    return str; // Return the original string if no trailing slash
  };
  const navigate = (url) => {
    const split = url.split(":");
    let tempUrl = url;
    if (split.length > 1) {
      tempUrl = split[0];
    }
    tempUrl = removeTrailingSlash(tempUrl);
    props.router.navigate(`${tempUrl}`);
    if (props.handleMenu && props.isMobile) {
      props.handleMenu(false);
    }
  };

  const onLogOut = () => {
    localStorage.clear("token");
    localStorage.clear("user");
    window.location.href = "/";
  };

  const renderMenuItems = () => {
    return (
      <Box sx={{ width: 200 }} role="presentation">
        <List>
          {props.items && props.items.length
            ? props.items.map((item, index) => {
                handleActiveItem(item);
                return (
                  <Fragment key={index}>
                    {item.children && item.children.length ? (
                      <ListItemDropDown
                        children={item.children}
                        active={item.active}
                        open={item.open}
                        icon={item.icon}
                        title={item.title}
                      />
                    ) : (
                      <ListItem
                        title={item.title}
                        active={item.active}
                        icon={item.icon}
                        onClick={() => navigate(item.url)}
                      />
                    )}
                  </Fragment>
                );
              })
            : null}
          <ListItem
            title={"Log out"}
            active={false}
            onClick={() => onLogOut()}
          />
        </List>
      </Box>
    );
  };

  useEffect(() => {
    handleProfileInfo();
  }, []);

  const handleProfileInfo = async () => {
    try {
      console.log(localStorage.getItem("user_id"), "userId");
      const response = await global.UF.dataProvider.get_v2(`users`, {
        id: localStorage.getItem("user_id"),
      });
      setProfile(response[0]);
    } catch (err) {
      global.UF.setAlertVisibility(true, err.toString(), "error");
      throw new Error(err);
    }
  };

  const renderCustomInfo = () => {
    return (
      <div style={{ fontSize: 12, color: "white" }}>
        <div>Logged in as: {profile?.email}</div>
        {/* <div>
          Budget:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(profile?.budget)}
        </div>
        <div>
          Total expanse this month:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(profile?.total)}
        </div>
        <div>
          Total budget:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(profile?.total_budget)}
        </div> */}
      </div>
    );
  };

  return (
    <Grid
      className={"nx_menu"}
      style={{
        gridAutoFlow: "row",
        gridTemplateRows: "89px auto",
        overflow: "hidden",
      }}
    >
      <Grid
        style={{ justifyContent: "center", alignItems: "center" }}
        className={"border_header_left"}
      >
        {renderCustomInfo()}
      </Grid>
      <Grid
        style={{
          justifyContent: "center",
          overflow: "hidden",
          overflowY: "auto",
          marginTop: "20px",
          // minHeight: "820px",
        }}
      >
        {renderMenuItems()}
      </Grid>
    </Grid>
  );
}

export default withRouter(Menu);
