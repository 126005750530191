import React from "react";
import "./log_in.scss";
import UF_Button from "../../../nix_components/ui_components/controls/nx_button.js";
import withRouter from "../../../nix_components/components/withRouter";
import Nx_Ctrl_TextInput from "../../../nix_components/ui_components/controls/nx_ctrl_textinput";
// import Secure from "./app/Screens/secure";

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isError: false,
      errMsgEmail: "",
      errMsgPassword: "",
    };
  }

  onChange = (val, field) => {
    this.state[field] = val;
    this.forceUpdate();
  };

  handleValidation = (callback) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      this.state.email !== null &&
      this.state.password !== null &&
      this.state.email.length &&
      this.state.password.length
    ) {
      if (String(this.state.email).toLowerCase().match(emailRegex)) {
        callback(true);
      } else {
        this.state.errMsgEmail = `Please insert right email format!`;
      }
    } else {
      this.state.errMsgEmail = `Both fields are required!`;
      this.state.errMsgPassword = `Both fields are required!`;
    }
    this.forceUpdate();
    callback(false);
  };

  handleLogin = () => {
    this.handleValidation((valid) => {
      console.log("validacija");
      if (valid) {
        let body = {
          email: this.state.email,
          password: this.state.password,
        };
        global.UF.makeRequest(
          `POST`,
          `/api/auth/login`,
          body,
          true,
          this.onSuccess,
          (error) => {
            this.state.errMsgEmail = "Invalid credentials!";
            this.state.errMsgPassword = "Invalid credentials!";
            this.forceUpdate();
          }
        );
      }
    });
  };

  onSuccess = (res) => {
    const data = res.body ? res.body : res;
    this.props.onLogin(JSON.stringify(data));
    this.props.router.navigate(`/profile`);
  };

  onKeyDown = (event) => {
    if (event.keyCode == 13) {
      this.handleLogin();
    }
  };

  render() {
    return (
      <div className="uf_login">
        <div className="uf_login_card">
          <div className="uf_login_login">
            <div className="uf_login_text">Log in</div>
            <div className="uf_login_textfield_wrapper">
              <Nx_Ctrl_TextInput
                onChange={(val) => this.onChange(val, `email`)}
                label="Email"
                type={"text"}
                error={this.state.errMsgEmail.length > 0}
                helperText={this.state.errMsgEmail}
                value={this.state.email}
                onKeyDown={this.onKeyDown}
                inputStyle={{ height: "25px", borderRadius: "10px" }}
                inputLabelStyle={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
              />
            </div>
            <div className="uf_login_textfield_wrapper">
              <Nx_Ctrl_TextInput
                label="Password"
                type="password"
                value={this.state.password}
                error={this.state.errMsgPassword.length > 0}
                helperText={this.state.errMsgPassword}
                onChange={(val) => this.onChange(val, `password`)}
                onKeyDown={this.onKeyDown}
                inputStyle={{ height: "25px", borderRadius: "10px" }}
                inputLabelStyle={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
              />
            </div>
            <div className={"uf_login_button_container"}>
              <UF_Button
                label="Log in"
                className="uf_login_login_button"
                onClick={this.handleLogin}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginScreen);
