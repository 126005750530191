import React, { useState, useEffect } from "react";

const ResetPassword = () => {
  const [uid, setUid] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uidFromUrl = urlParams.get("uid");
    setUid(uidFromUrl);
    if (uidFromUrl) {
      // Verify the UID with the server
      fetch(`https://tm.nix.mk/api/auth/forgot-password-token/${uidFromUrl}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data === "OK!") {
            setIsFormVisible(true);
          } else {
            alert("Invalid UID.");
          }
        })
        .catch(() => {
          alert("Error verifying UID.");
        });
    } else {
      alert("No UID provided in the URL.");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    // Send the reset password request to the server
    fetch("https://tm.nix.mk/api/auth/reset-password", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password, uid }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data === "OK!") {
          alert("Password reset successful, go back to application.");
        } else {
          alert("Password reset failed. Token is not valid.");
        }
      })
      .catch(() => {
        alert("Password reset failed. Token is not valid.");
      });
  };

  return (
    <div className="reset-password-container">
      {isFormVisible ? (
        <div className="forgot-password-form">
          <h2 className="reset-password-h2">Reset password</h2>
          <form onSubmit={handleSubmit}>
            <input
              className="input-forgot-password"
              type="password"
              placeholder="Enter new password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength="8"
            />
            <input
              className="input-forgot-password"
              type="password"
              placeholder="Confirm password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              minLength="8"
            />
            <input
              className="submit-forgot-password"
              type="submit"
              value="Reset password"
            />
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default ResetPassword;
