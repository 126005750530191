const referenceTypes = {
  local: "local",
  remote: "remote",
};

const subscriptionTypes = {
  1: "Free Trial",
  2: "Month",
  3: "Year",
};

const activeSubscription = {
  0: "Not Active",
  1: "Active",
};

const referenceConfigs = {
  subscriptionTypes: {
    type: referenceTypes.local,
    source: subscriptionTypes,
  },

  activeSubscription: {
    type: referenceTypes.local,
    source: activeSubscription,
  },
};

class DataReferences {
  references = {};
  reference_configs = referenceConfigs;
  data_provider = null;

  constructor(data_provider) {
    this.data_provider = data_provider;

    Object.keys(referenceConfigs).forEach((key) => {
      if (referenceConfigs[key].type == referenceTypes.local) {
        this.references[key] = [];
        Object.keys(referenceConfigs[key].source).forEach((item) => {
          this.references[key].push({
            id: item,
            label: referenceConfigs[key].source[item],
          });
        });
      }
    });
  }

  search = (reference, search) => {
    const data = this.references[reference];
    if (data) {
      let tempData = data.map((ref) => {
        if (ref.label.toLowerCase().includes(search.toLowerCase())) {
          return `'${ref.id.toString()}'`;
        } else {
          return null;
        }
      });
      tempData = tempData.filter((ref) => ref !== null);
      return tempData;
    }
  };

  getReferenceLabel_v2 = async (reference, value) => {
    let label = "";
    if (reference && value) {
      if (!this.references[reference]) {
        const data = await this.get_v2(reference);
        label = data.find((ref) => ref.id == value)?.label || "";
      } else {
        label =
          this.references[reference].find((ref) => ref.id == value)?.label ||
          "";
      }
      return label;
    }
  };

  getReferenceLabel = (reference, value) => {
    let label = "";
    if (reference !== null && this.references[reference] && value !== null) {
      label = this.references[reference].find((ref) => ref.id == value)
        ? this.references[reference].find((ref) => ref.id == value).label
        : "";
    }
    return label;
  };

  get = (reference, callBack, force = false) => {
    if (
      this.references[reference] &&
      (force == false ||
        referenceConfigs[reference].type == referenceTypes.local)
    ) {
      setTimeout(() => {
        callBack(this.references[reference]);
      }, 100);
    } else {
      let filters = referenceConfigs[reference].filters
        ? referenceConfigs[reference].filters
        : {};

      const table = referenceConfigs[reference].table;

      this.data_provider.get(table, filters, (data) => {
        this.references[reference] = data.map((item) => {
          const newItem = {
            id: item[referenceConfigs[reference].id],
            label:
              typeof referenceConfigs[reference].label === "function"
                ? referenceConfigs[reference].label(item)
                : item[referenceConfigs[reference].label],
          };

          if (referenceConfigs[reference].hasOwnProperty("groupBy")) {
            if (
              referenceConfigs[reference].hasOwnProperty("groupByException")
            ) {
              if (
                !referenceConfigs[reference][`groupByException`].includes(
                  newItem.label
                )
              ) {
                newItem.group = item[referenceConfigs[reference].groupBy];
              }
            } else {
              newItem.group = item[referenceConfigs[reference].groupBy];
            }
          }
          return newItem;
        });
        callBack(this.references[reference]);
      });
    }
  };

  get_v2 = async (reference, force = false) => {
    if (
      this.references[reference] &&
      (force == false ||
        referenceConfigs[reference].type == referenceTypes.local)
    ) {
      return Promise.resolve(this.references[reference]);
    } else {
      let filters = referenceConfigs[reference].filters
        ? referenceConfigs[reference].filters
        : {};
      const table = referenceConfigs[reference].table;

      try {
        const data = await this.data_provider.get_v2(table, filters);

        this.references[reference] = data.map((item) => {
          const newItem = {
            id: item[referenceConfigs[reference].id],
            label:
              typeof referenceConfigs[reference].label === "function"
                ? referenceConfigs[reference].label(item)
                : item[referenceConfigs[reference].label],
          };

          if (referenceConfigs[reference].hasOwnProperty("groupBy")) {
            if (
              referenceConfigs[reference].hasOwnProperty("groupByException")
            ) {
              if (
                !referenceConfigs[reference][`groupByException`].includes(
                  newItem.label
                )
              ) {
                newItem.group = item[referenceConfigs[reference].groupBy];
              }
            } else {
              newItem.group = item[referenceConfigs[reference].groupBy];
            }
          }
          return newItem;
        });

        return this.references[reference];
      } catch (error) {
        console.error("Error:", error);
        return [];
      }
    }
  };
}

export default DataReferences;
