import React, { Fragment } from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import ProfileFilters from "./ProfileFilters";
import Nx_Button from "../../../nix_components/ui_components/controls/nx_button";
import withRouter from "../../../nix_components/components/withRouter";
import ParticipationExapandableModal from "./modals/ParticipationClickModal";
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
    };
    global.UF.dataProvider.datastructure[
      "view_profile_participations"
    ].filters = {
      user_id: localStorage.getItem("user_id"),
    };
  }

  gridProps = {
    // fields: ["date", "category_id", "user", "cost", "source_id", "type"],
    fields: ["id"],
  };

  gridMobileProps = {
    // fields: ["date", "category_id", "user", "cost"],
    fields: ["id"],
  };

  tools = {
    addDeposit: () => {
      return (
        <Nx_Button
          label={"Add Deposit"}
          variant="text"
          onClick={() => this.props.router.navigate(`/deposits`)}
        />
      );
    },
    addExpense: () => {
      return (
        <Nx_Button
          label={"Add Expense"}
          variant="text"
          onClick={() => this.props.router.navigate(`/expenses`)}
        />
      );
    },
  };

  componentDidMount = async () => {
    console.log("usao vamp");
    try {
      // console.log(localStorage.getItem("user_id"));
      const response = await global.UF.dataProvider.get_v2(`users`, {
        id: localStorage.getItem("user_id"),
      });
      console.log("ovo je response", response);
      this.setState({ profile: response[0] });
    } catch (err) {
      global.UF.setAlertVisibility(true, err.toString(), "error");
      throw new Error(err);
    }
  };

  generateAndSetFilters = (state) => {
    const filters = {};
    const { selected_categories, date_from, date_to } = state;

    if (selected_categories && selected_categories.length > 0) {
      filters.category = selected_categories;
    }

    // filters.user_id = localStorage.getItem("user_id");
    filters.user_id = localStorage.getItem("user_id");

    const date_from_obj = new Date(date_from);
    const date_to_obj = new Date(date_to);
    const mysqlTimeStampFrom = date_from_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const mysqlTimeStampTo = date_to_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    if (date_from || date_to) {
      filters.custom_filter = `${
        mysqlTimeStampTo
          ? `'${mysqlTimeStampTo}' >= view_profile_participations.date`
          : ""
      } ${mysqlTimeStampTo && mysqlTimeStampFrom ? "AND" : ""} ${
        mysqlTimeStampFrom
          ? `view_profile_participations.date >= '${mysqlTimeStampFrom}'`
          : ""
      }`;
    }

    this.setState({ filters }, () => {
      global.UF.dataProvider.datastructure[
        "view_profile_participations"
      ].filters = this.state.filters;
      this.fetchData();
    });
  };

  fetchData = () => {};

  getFetchData = (fetchData, filters) => {
    this.fetchData = fetchData;
    this.setState({ filters });
  };

  renderCustomInfo = () => {
    const { profile } = this.state;
    return (
      <div style={{ fontSize: 12 }}>
        <div>Logged in as: {profile?.name}</div>
        <div>
          Budget:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(profile?.budget)}
        </div>
        <div>
          Total Expense:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(parseInt(profile?.total_expense))}
        </div>
        <div>
          Total Deposit:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(parseInt(profile?.total_deposit))}
        </div>
      </div>
    );
  };

  renderMoreInfo = () => {
    return this.renderCustomInfo();
  };

  handleRowClick = async (row) => {
    this.setState({ activeItem: row.row });
  };

  renderExpandableModal = () => {
    const { type_id, type } = this.state.activeItem;
    return (
      <ParticipationExapandableModal
        show={Boolean(this.state.activeItem)}
        handleClose={() => this.setState({ activeItem: null })}
        type_id={type_id}
        type={type}
      />
    );
  };

  renderProfileAdditional = () => {
    const { profile } = this.state;
    return (
      <div
        style={{
          fontWeight: "500",
          color: "rgba(34, 42, 96, 0.9);",
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        <div>
          Budget:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(profile?.budget)}
        </div>
        <div>
          Total expanse this month:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(profile?.total)}
        </div>
        <div>
          Total budget:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(profile?.total_budget)}
        </div>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        {Boolean(this.state.activeItem) && this.renderExpandableModal()}
        <UF_Content
          table="users"
          title="Subscriptions"
          permission="user"
          gridProps={this.gridProps}
          gridMobileProps={this.gridMobileProps}
          fetchData={this.getFetchData}
          tools={this.tools}
          edit={false}
          export={false}
          delete={false}
          add={false}
          renderMoreInfo={this.renderMoreInfo()}
          renderProfileAdditional={this.renderProfileAdditional}
          // onRowClick={this.handleRowClick}
          sync={false}
        />
      </Fragment>
    );
  }
}

export default withRouter(Profile);
