import React, { Fragment } from "react";
import Secure from "./app/Screens/secure";
import Public from "./app/Screens/public";
import UF from "./app/nix_components/utils/UF";
import DataProvider from "./app/nix_components/data/DataProvider";
import Nx_Loader from "./app/nix_components/components/nx_loader";
import Nx_Snackbar from "./app/nix_components/ui_components/alerts/nx_snackbar";
import NxProgressiveLoader from "./app/nix_components/components/NxProgressLoader";
import NxLoaderRequests from "./app/nix_components/components/nx_loader_requests";

class App extends React.Component {
  constructor(props) {
    super(props);
    global.UF = new UF();
    global.UF.dataProvider = new DataProvider();

    this.state = {
      authenticated:
        global.localStorage.getItem("token") !== undefined &&
        global.localStorage.getItem("token") !== null,
      user: null,
      initializing: true,
    };
  }

  login = (loginObj) => {
    try {
      const user = JSON.parse(loginObj);
      if (user.role == "user") {
        this.setState({ user: null, authenticated: false });
        console.log("nije admin");
        return;
      }

      localStorage.setItem("user", loginObj);
      console.log(user);

      if (user.hasOwnProperty("access_token")) {
        localStorage.setItem("token", user.access_token);
      }

      if (user.hasOwnProperty("settings") && user.settings.user_id) {
        localStorage.setItem("user_id", user.settings.user_id);
      }

      this.setState({ user: user, authenticated: true });
    } catch {
      this.setState({ user: null, authenticated: false });
    }
  };

  logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    window.location.href = "/";
  };

  setUser = () => {
    const user =
      localStorage.getItem("user") != null &&
      typeof JSON.parse(localStorage.getItem("user")) === "object"
        ? JSON.parse(localStorage.getItem("user"))
        : null;

    if (!user) {
      this.setState({ authenticated: false, user: null });
      return;
    }

    if (
      user.hasOwnProperty("access_token") &&
      user.hasOwnProperty("settings") &&
      user.settings.user_id
    ) {
      this.setState({ authenticated: true, user: user });
      return;
    }

    this.setState({ authenticated: false, user: null });
  };

  componentDidMount = async () => {
    const link = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = `../../assets/images/office-icon.png`;
    }
    try {
      this.setUser();
      document.title = "Office";
      this.setState({ initializing: false });
    } catch (err) {
      console.error(`Error : ${err}`);
    }
  };

  renderApp = () => {
    if (this.state.authenticated) {
      return <Secure user={this.state.user} />;
    }

    return <Public onLogin={this.login} />;
  };

  render() {
    if (this.state.initializing) {
      return <Nx_Loader show={true} />;
    }

    return (
      <Fragment>
        <Nx_Snackbar />
        <Nx_Loader />
        <NxProgressiveLoader />
        <NxLoaderRequests />
        {this.renderApp()}
      </Fragment>
    );
  }
}

export default App;
